import * as React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../layouts"
import Seo from "../components/seo"
import {
  BreadCrumb,
  Container,
  Section,
  MarkdownBody,
  MarkdownContent,
  SectionTitle,
  SectionDescription,
} from "../components/Section"
import {
  HeroContainer,
  HeroStatic,
  HeroCaption,
  HeroTitle,
  HeroDescription,
  HeroMedia,
} from "../components/Hero"
import ExpertSection from "../components/Expert"
import PrimaryButton from "../components/Button/PrimaryButton"
import PhoneIcon from "../components/Icons/PhoneIcon"
import BreakpointUp from "../components/Media/BreakpointUp"
import Handpointer from "../svg/handpointer.svg"

const HeroActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -22px;
  ${BreakpointUp.lg` 
        margin-top: -26px;
    `}
  > a {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;
    .btn {
      width: 100%;
    }
  }
`
const ListCityContainer = styled.div`
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
`
const ListCity = styled.ul`
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0 -5px;
  font-weight: 500;
  color: #000;
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  line-height: 26px;
  @media (min-width: 1600px) {
    font-size: 18px;
    line-height: 32px;
  }
  & li {
    margin: 0;
    &:before {
      content: url(${Handpointer});
      display: inline-block;
      width: 38px;
      vertical-align: top;
    }
  }
`
const ListCityItem = styled.li`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 5px;

  flex: 0 0 50%;
  max-width: 50%;
  ${BreakpointUp.sm`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
  ${BreakpointUp.md`
        flex: 0 0 25%;
        max-width: 25%;
    `}

    > a {
    color: #000;
    font-weight: 400;
    &:hover,
    &:focus {
      color: #d51333;
    }
  }
`

const StateTemplatePage = ({ location, data }) => {
  const pageData = data.contentfulState
  const cities = data.allContentfulCity.edges.filter(
    item => item.node.state.name === pageData.name
  )
  const stateURL = pageData.url
  return (
    <Layout isHeader={true} location={location}>
      <Seo title={pageData.heroTitle} description="Steel Buildings Florida" />
      <Section
        xpt="114px"
        mpt="94px"
        pt="64px"
        xpb="0"
        mpb="0"
        pb="0"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <HeroContainer bgColor="transparent">
            <HeroStatic
              bgAfterBefore="none"
              pt="30px"
              xpb="90px"
              mpb="70px"
              pb="50px"
            >
              <HeroCaption>
                <BreadCrumb position="static" translate="0">
                  <Link to="/">Home</Link>
                  <span>{pageData.name}</span>
                </BreadCrumb>
                <HeroTitle color="#000">{pageData.heroTitle}</HeroTitle>
                <HeroDescription maxWidth="1496px" color="#666">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: pageData.heroDescription.childMarkdownRemark.html,
                    }}
                  />
                </HeroDescription>
              </HeroCaption>
              <HeroMedia>
                <GatsbyImage
                  image={getImage(pageData.heroImage)}
                  alt={pageData.heroTitle}
                />
                <HeroActions>
                  <a href="tel:8004407309" aria-label="Phone">
                    <PrimaryButton
                      textAfter="(800) 440-7309"
                      icon={<PhoneIcon />}
                      size="lg"
                    />
                  </a>
                </HeroActions>
              </HeroMedia>
            </HeroStatic>
            <hr />
          </HeroContainer>
        </Container>
      </Section>

      <Section
        xpt="90px"
        mpt="60px"
        pt="30px"
        xpb="90px"
        mpb="60px"
        pb="30px"
        bgColor="transparent"
      >
        <Container maxWidth="1496px" pl="3%" pr="3%">
          <SectionTitle>{pageData.citiesSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1140px">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.citiesSectionDescription.childMarkdownRemark.html,
              }}
            />
          </SectionDescription>
          <ListCityContainer>
            <ListCity>
              {cities.map(city => {
                const cityURL = stateURL + city.node.url
                return (
                  <ListCityItem key={city}>
                    <Link to={cityURL}>{city.node.name}</Link>
                  </ListCityItem>
                )
              })}
            </ListCity>
          </ListCityContainer>
        </Container>
      </Section>

      <ExpertSection />

      <Section
        xpt="60px"
        mpt="40px"
        pt="30px"
        xpb="60px"
        mpb="40px"
        pb="30px"
        bgColor="transparent"
      >
        <Container maxWidth="100%" pl="3%" pr="3%">
          <MarkdownBody>
            <MarkdownContent maxWidth="1496px">
              <div dangerouslySetInnerHTML={{__html: pageData.content.childMarkdownRemark.html}} />
            </MarkdownContent>
          </MarkdownBody>
        </Container>
      </Section>
    </Layout>
  )
}

export default StateTemplatePage

export const query = graphql`
  query StateTemplatePageQuery($id: String!) {
    contentfulState(id: { eq: $id }) {
      id
      url
      name
      heroTitle
      heroDescription {
        heroDescription
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        gatsbyImageData(quality: 100)
      }
      citiesSectionTitle
      citiesSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulCity {
      edges {
        node {
          id
          name
          url
          state {
            name
            url
          }
        }
      }
    }
  }
`
